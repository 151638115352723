import { extend, override } from 'flarum/extend';
import HeaderPrimary from 'flarum/components/HeaderPrimary';
import HeaderSecondary from 'flarum/components/HeaderSecondary';
import Button from 'flarum/components/Button';
import LinkButton from "flarum/components/LinkButton";
import TagsPage from 'flarum/tags/components/TagsPage';


import Page from 'flarum/components/Page';
import IndexPage from 'flarum/components/IndexPage';
import listItems from 'flarum/helpers/listItems';
import humanTime from 'flarum/helpers/humanTime';
import tagIcon from 'flarum/tags/helpers/tagIcon';

import tagLabel from 'flarum/tags/helpers/tagLabel';
import sortTags from 'flarum/tags/utils/sortTags';

app.initializers.add('elsontan/custom-banners', () => {
  // add banners
  extend(HeaderPrimary.prototype, 'config', (isInitialized, context) => {
    // if (isInitialized) { return; }
    if (location.pathname === "/" &&
      location.hash.length <= 1 &&
      location.search.length <= 1) {

      if (document.getElementsByClassName('custom-banner').length) {
        return;
      }

      var indexBannerUrl = app.forum.attribute('index_banner');

      var appElement = document.getElementsByClassName('App-content')[0];
      var bannerElement = document.createElement('div');

      if (indexBannerUrl) {
        bannerElement.className = 'custom-banner';
        bannerElement.innerHTML = `<img src="${indexBannerUrl}" style="width:100%"/>`;
        appElement.prepend(bannerElement);
      }
    }
  });

  // override menu links
  extend(HeaderPrimary.prototype, 'items', function (items) {
    items.add(
      'topicsLink',
      LinkButton.component({
        children: 'Topics',
        className: 'Button Button--link',
        href: '/tags'
      }),
      0
    );

    items.add(
      'discussionLink',
      LinkButton.component({
        children: 'Discussions',
        className: 'Button Button--link',
        href: '/all'
      }),
      0
    );
    return items;
  });

  // override tags page for custom view
  override(TagsPage.prototype, 'view', function () {
    const pinned = this.tags.filter(tag => tag.position() !== null);
    const cloud = this.tags.filter(tag => tag.position() === null);

    return (
      <div className="TagsPage">
        {IndexPage.prototype.hero()}
        <div className="container">
          <div className="tagsHeader" style={{
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '.9rem',
            backgroundImage: 'linear-gradient(to right, rgb(0, 100, 46) 0%, rgb(127, 177, 150) 100%)'
          }}>
            <div style={{ paddingTop: '3rem', paddingBottom: '3rem', textAlign: 'center'}}>
              <h1 style={{ fontWeight: 'normal', color: '#fff' }}>Explore by Topic</h1>
            </div>
          </div>
          {/*<nav className="TagsPage-nav IndexPage-nav sideNav" config={IndexPage.prototype.affixSidebar}>*/}
          {/*  <ul>{listItems(IndexPage.prototype.sidebarItems().toArray())}</ul>*/}
          {/*</nav>*/}

          <div className="TagsPage-content sideNavOffset">
            <ul className="TagTiles">
              {pinned.map(tag => {
                const lastPostedDiscussion = tag.lastPostedDiscussion();
                const children = sortTags(app.store.all('tags').filter(child => child.parent() === tag));

                return (
                  <li className={'TagTile ' + (tag.color() ? 'colored' : '')}
                      style={{borderRadius: '.9rem', backgroundColor: tag.color()}}>
                    <div>
                      <a className="TagTile-info" href={app.route.tag(tag)} config={m.route}>
                        <h3 className="TagTile-name">
                          {tag.icon() && tagIcon(tag, {}, { useColor: false })}&nbsp;
                          {tag.name()}
                        </h3>
                        <p className="TagTile-description">{tag.description()}</p>
                        {children
                          ? (
                            <div className="TagTile-children">
                              {children.map(child => [
                                <a href={app.route.tag(child)} config={function(element, isInitialized) {
                                  if (isInitialized) return;
                                  $(element).on('click', e => e.stopPropagation());
                                  m.route.apply(this, arguments);
                                }}>
                                  {child.name()}
                                </a>,
                                ' '
                              ])}
                            </div>
                          ) : ''}
                      </a>
                      {lastPostedDiscussion
                        ? (
                          <a className="TagTile-lastPostedDiscussion"
                             href={app.route.discussion(lastPostedDiscussion, lastPostedDiscussion.lastPostNumber())}
                             config={m.route}>
                            <span className="TagTile-lastPostedDiscussion-title">{lastPostedDiscussion.title()}</span>
                            {humanTime(lastPostedDiscussion.lastPostedAt())}
                          </a>
                        ) : (
                          <span className="TagTile-lastPostedDiscussion"/>
                        )}
                    </div>
                  </li>
                );
              })}
            </ul>

            {cloud.length ? (
              <div className="TagCloud">
                {cloud.map(tag => [
                  tagLabel(tag, {link: true}),
                  ' ',
                ])}
              </div>
            ) : ''}
          </div>
        </div>
      </div>
    );
  })

});

